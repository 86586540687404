import React, { useEffect, useState } from 'react'
import { CROSSED, USER_PROFILE } from '../../../Middleware/images';
import "../../../assets/style/addCustomers.css";
import { useNavigate } from 'react-router-dom';
import ReactLoader from '../../../Loader/react-loader';
import ERR_MESSAGE from '../../../Helper/error-helper';
import uploadFileToS3 from "../../../Helper/fileUpload"
import { Helmet } from 'react-helmet';
import TITLES from '../../../Helper/title';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { ToastContainer, toast } from 'react-toastify';
import ROUTES from '../../../Middleware/routes';

const AddStaff = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState("");
    const [branch, setBranch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [role, setRole] = useState([]);
    // const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [specialization, setSpecialization] = useState([]);
    const [specialization_name, setSpecializationName] = useState('');
    const [info, setInfo] = useState({
        name: "",
        mobileNo: "",
        whatsappNo: "",
        experience: "",
        emergency_no: "",
        email: "",
        role: "",
        branch: localStorage.getItem("branch_id") == 1 && localStorage.getItem("role_id") == 1 ?  localStorage.getItem("branch_id") :"",
        gender: "",
        blood: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        city: "",
        country: "",
        zipCode: "",
        about: "",
        shift: '',
        password: ''
    });

    function handleChange2(event) {
        setErrors("")
        setSpecializationName(event.target.value);
    }
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const emojiSymbolRegex = /[^\p{L}\s]/u;
        const whatsappRegex = /^\d+$/;
        let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
        const { name, whatsappNo, mobileNo, emergency_no, email, role, branch, gender, address_1,
            state,
            city,
            zipCode,
            about, shift, password } = info

        if (!name || name?.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
        } else if (name.length < 3) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
        }
        else if (name.length > 30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
        }
        else if (emojiSymbolRegex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
        }


        if (!mobileNo || mobileNo?.trim().length === 0) {
            formIsValid = false;
            errors["mobileNo"] = ERR_MESSAGE.MOBILE_EMPTY

        }
        else if (!whatsappRegex.test(mobileNo)) {
            formIsValid = false;
            errors["mobileNo"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (mobileNo.length < 10 && mobileNo?.length >= 1) {
            formIsValid = false;
            errors["mobileNo"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }
        if (!whatsappNo || whatsappNo?.trim().length === 0) {
            formIsValid = false;
            errors["whatsappNo"] = ERR_MESSAGE.WHATSAPP_EMPTY
        }
        else if (!whatsappRegex.test(whatsappNo)) {
            formIsValid = false;
            errors["whatsappNo"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (whatsappNo.length < 10) {
            formIsValid = false;
            errors["whatsappNo"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }

        if (!whatsappRegex.test(emergency_no) && emergency_no?.length >= 1) {
            formIsValid = false;
            errors["emergency_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (emergency_no.length < 10 && emergency_no?.length >= 1) {
            formIsValid = false;
            errors["emergency_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }


        if (!email || email?.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
        }

        if (!role || role.length === 0) {
            formIsValid = false;
            errors["role"] = ERR_MESSAGE.EMPTY_ROLE;
        }
        if (!shift || shift.length === 0) {
            formIsValid = false;
            errors["shift"] = ERR_MESSAGE.EMPTY_STAFF_TIME;
        }

        if (!branch || branch.length === 0) {
            formIsValid = false;
            errors["branch"] = ERR_MESSAGE.EMPTY_BRANCH;
        }

        if (!gender || gender.length === 0) {
            formIsValid = false;
            errors["gender"] = ERR_MESSAGE.EMPTY_GENDER;
        }
        if (!address_1 || address_1.length === 0) {
            formIsValid = false;
            errors["address_1"] = ERR_MESSAGE.EMPTY_ADDRESS;
        }
        if (!state || state.length === 0) {
            formIsValid = false;
            errors["state"] = ERR_MESSAGE.EMPTY_STATE;
        }
        if (!city || city.length === 0) {
            formIsValid = false;
            errors["city"] = ERR_MESSAGE.EMPTY_CITY;
        }
        if (!zipCode || zipCode.length === 0) {
            formIsValid = false;
            errors["zipCode"] = ERR_MESSAGE.EMPTY_ZIPCODE;
        }
        if (about.length > 600) {
            formIsValid = false;
            errors["about"] = ERR_MESSAGE.INVALID_ABOUT;
        }
        if (role == '1') {
            if (!password) {
                formIsValid = false;
                errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY;
            }
            else if (passwordReg.test(password) === false) {
                formIsValid = false;
                errors["password"] = ERR_MESSAGE.INVALID_PASSWORD
            }
        }

        if (role == "3") {
            if (!about || about.length === 0) {
                formIsValid = false;
                errors["about"] = ERR_MESSAGE.EMPTY_ABOUT;
            }
            else if (about.length > 600) {
                formIsValid = false;
                errors["about"] = ERR_MESSAGE.INVALID_ABOUT;
            }
            if (specialization.length == 0) {
                formIsValid = false;
                errors["specialization"] = ERR_MESSAGE.EMPTY_SPECIALIZATION;
            }
        }
        setErrors(errors);
        return formIsValid;
    }

    const fileChange = (e) => {
        setErrors("");
        if (e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                const imageURL = URL.createObjectURL(file);
                setImageUrl(imageURL);
                // setSelectedFile(file)
                fileUpload(file)
            }
        }
    };

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        const regex = /^[0-9]*$/;
        if (name === 'whatsappNo' && !regex.test(value)) {
            return;
        }
        if (name === 'mobileNo' && !regex.test(value)) {
            return;
        }
        if (name === 'emergency_no' && !regex.test(value)) {
            return;
        }
        if (name === 'mobileNo') {
            setInfo({
                ...info,
                [name]: value,
                whatsappNo: value
            });
        } else {
            setInfo({
                ...info,
                [name]: value
            });
        }
    }

    const handleChange1 = (event) => {
        setErrors("")
        const { name, value } = event.target;
        if (value.length > 600) {
            setErrors({ ...errors, about: ERR_MESSAGE.INVALID_ABOUT });
            setInfo({ ...info, [name]: value });
        } else {
            setInfo({ ...info, [name]: value });
            setErrors("")
        };
    }

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setFileUrl(fileUrl)
            }
        }
        catch (error) {
        }
    };

    const specializationNamesArray = specialization?.map(spec => spec?.name);
    async function postApi() {
        let data = JSON.stringify({
            "profile_pic": fileUrl,
            "role_id": info.role,
            "branch_id": info.branch,
            "name": info?.name,
            "whatsapp_no": info.whatsappNo,
            "emergency_no": info.emergency_no,
            "mobile_number": info.mobileNo,
            "experience": info.experience,
            "blood_group": info.blood,
            "email": info?.email,
            "gender": info?.gender,
            "address_1": info?.address_1,
            "about": info.about,
            "specialization": specializationNamesArray,
            "address_2": info.address_2,
            "state": info.state,
            "city": info.city,
            "pincode": info.zipCode,
            "shift_hours": info.shift,
            "password": info.password
        });
        setLoading(true);
        const result = await Services.postService("admin/add-trainer", data);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("message", result.message)
            navigate(ROUTES.STAFF);
            setLoading(false);
        }
        else {
            toast.error(result.message)
            setLoading(false);
        }
    }

    function specializationApi() {
        setErrors('')
        if (specialization_name) {
            const newSpecialization = { id: Date.now(), name: specialization_name };
            const updatedSpecialization = [...specialization, newSpecialization];
            setSpecialization(updatedSpecialization);
            localStorage.setItem('specialization', JSON.stringify(updatedSpecialization));
            setSpecializationName("")
        }
    }

    function handleDeleteSpecialization(id) {
        setErrors('')
        const updatedSpecialization = specialization.filter(item => item.id !== id);
        setSpecialization(updatedSpecialization);
        localStorage.setItem('specialization', JSON.stringify(updatedSpecialization));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            postApi()
        }
    };

    async function getApi() {
        const company_id = localStorage.getItem("company_id")
        let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setRole(result?.body?.roleData)
            setBranch(result?.body?.branch)
        }
        else {
        }
    }
    useEffect(() => {
        getApi()
    }, [])


    return (
        <div>
            <Helmet>
                <title>{TITLES.ADD_STAFF}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <section className="add-customer common-bg form-role">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="add-customer-head mb-2 mb-lg-5">
                                <h2 className="add-customer-text fw-600">Add Staff</h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-2">
                                        <div className=" position-relative">
                                            <div className={`profile-pic  ${imageUrl !== "" ? 'profile-pic-change' : ''}`}>
                                                <div className={imageUrl === "" ? "profile-upper-pic" : ""}>
                                                    <img src={imageUrl ? imageUrl : USER_PROFILE} className={`img-fluid  ${imageUrl !== "" ? 'img-preview ' : ""}`} id="blah" />
                                                    <p className="f14-size text-black fw-600">{imageUrl ? "" : "Upload Image"}</p>
                                                </div>
                                            </div>
                                            <input type="file" name="imageUrl" className="input-profile" id="image" accept=".jpg, .jpeg, .png" onChange={fileChange} />
                                            {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-10">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">name</label>
                                                    <input type="text" name='name' value={info.name} onChange={handleChange} className="form-control common-input" placeholder="Enter Name" />
                                                    {errors.name && <span className="err_msg">{errors.name}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Mobile No</label>
                                                    <input type="text" name='mobileNo' value={info.mobileNo?.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Mobile No" />
                                                    {errors.mobileNo && <span className="err_msg">{errors.mobileNo}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Whatsapp  No</label>
                                                    <input type="text" name='whatsappNo' value={info.whatsappNo?.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Whatsapp No" />
                                                    {errors.whatsappNo && <span className="err_msg">{errors.whatsappNo}</span>}

                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Emergency No</label>
                                                    <input type="text" name='emergency_no' value={info.emergency_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Emergency No" />
                                                    {errors.emergency_no && <span className="err_msg">{errors.emergency_no}</span>}


                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Experience</label>
                                                    <input type="text" name='experience' value={info.experience} onChange={handleChange} className="form-control common-input" placeholder="Enter Experience" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input type="text" name='email' value={info.email} onChange={handleChange} className="form-control common-input" placeholder="Enter Email" />
                                                    {errors.email && <span className="err_msg">{errors.email}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Role</label>
                                                    <select type="text" name='role' value={info?.role} onChange={handleChange} className="form-select common-input" aria-label="Enter Role">
                                                        <option value="">Select</option>
                                                        {
                                                            role?.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id}>{item?.name}</option>

                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    {errors.role && <span className="err_msg">{errors.role}</span>}
                                                </div>
                                            </div>
                                            {
                                                info?.role == 1 &&
                                                <> <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">Password</label>
                                                        <input type="text" name='password' value={info.password} onChange={handleChange} className="form-control common-input" placeholder="Enter Pswword" />
                                                        {errors.password && <span className="err_msg">{errors.password}</span>}
                                                    </div>
                                                </div>
                                                </>
                                            }

                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Shift time (in hours)</label>
                                                    <select name='shift' value={info.shift} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                                        <option value="" >Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                    {errors.shift && <span className="err_msg">{errors.shift}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Branch</label>
                                                    {
                                                        localStorage.getItem("role_id") == 1
                                                            ?
                                                            <select type="text" name='branch' value={info.branch} onChange={handleChange} className="form-select common-input" disabled aria-label="Enter Role">
                                                                {/* <option value="">Select</option> */}
                                                                {
                                                                    branch.filter(item => item.id == localStorage.getItem("branch_id"))?.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                                                        )
                                                                    })
                                                                }
                                                            </select> :
                                                            <select type="text" name='branch' value={info.branch} onChange={handleChange} className="form-select common-input"  aria-label="Enter Role">
                                                                <option value="">Select</option>
                                                                {
                                                                    branch?.map((item) => {
                                                                        return (
                                                                            <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                    }

                                                    {errors.branch && <span className="err_msg">{errors.branch}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Gender</label>
                                                    <select name='gender' value={info.gender} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                                        <option value="" >Select</option>
                                                        <option value="2">Female</option>
                                                        <option value="1">Male</option>
                                                        <option value="3">Others</option>
                                                    </select>
                                                    {errors.gender && <span className="err_msg">{errors.gender}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Blood group</label>
                                                    <select name='blood' value={info.blood} onChange={handleChange} className="form-select common-input" aria-label="Blood group select">
                                                        <option value="">Select</option>
                                                        <option value="A+">A+</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Address line 1</label>
                                                    <input type="text" name='address_1' value={info.address_1} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />
                                                    {errors.address_1 && <span className="err_msg">{errors.address_1}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Address line 2(optional)</label>
                                                    <input type="text" name='address_2' value={info.address_2} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />

                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">state</label>
                                                    <input type="text" name='state' value={info.state} onChange={handleChange} className="form-control common-input" placeholder="Enter State" />
                                                    {errors.state && <span className="err_msg">{errors.state}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">city</label>
                                                    <input type="text" name='city' value={info.city} onChange={handleChange} className="form-control common-input" placeholder="Enter City" />
                                                    {errors.city && <span className="err_msg">{errors.city}</span>}
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">zip code</label>
                                                    <input type="number" name='zipCode' value={info.zipCode.slice(0, 6)} onChange={handleChange} className="form-control common-input" placeholder="Enter Zip code" />
                                                    {errors.zipCode && <span className="err_msg">{errors.zipCode}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">About </label>
                                                    <textarea name="about" className="form-control common-input remark-textarea" rows="5" onChange={handleChange1}
                                                        placeholder="Enter" value={info?.about}></textarea>
                                                    {errors.about && <span className="err_msg">{errors.about}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                    <label className="form-label">Specializations </label>
                                                </div>
                                                <div>
                                                    <div className='d-flex gap-2 align-items-strectch mb-3'>
                                                        <div className='col-lg-3 col-lg-3 col-sm-3 col-9'>
                                                            <input type="text" name="specialization_name" onChange={handleChange2}
                                                                value={specialization_name} className='form-control common-input' placeholder='Enter' onKeyDown={(e) => {
                                                                    if (e.code == "Enter"
                                                                    ) {
                                                                        specializationApi()
                                                                    }
                                                                }} />
                                                        </div>
                                                        <button type="button" className="add-btn btn add-field  next-btn" onClick={specializationApi}>ADD</button>

                                                    </div>
                                                    {errors.specialization && <span className="err_msg">{errors.specialization}</span>}

                                                </div>
                                                <div className="d-flex align-items-center flex-wrap my-2 gap-2">
                                                    {
                                                        specialization?.map((item) => (
                                                            <div key={item?.id}>
                                                                <span className="specializations-span d-flex align-items-center gap-3" style={{ cursor: 'pointer' }}>
                                                                    {item?.name}  <img src={CROSSED} onClick={() => handleDeleteSpecialization(item.id)}
                                                                        alt="" style={{ cursor: "pointer" }} /> </span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" className="next-btn">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
}

export default AddStaff;