import React, { useEffect, useState } from 'react'
import { ADD_ICON, EDIT_ICON, DELETE_ICON, CROSS_BTN, USER_PROFILE, SEARCH_ICON, PAUSE, LEFT, THREE_DOTS, CALANDER } from "../../../Middleware/images";
import "../../../assets/style/customer-leads.css"
import { useNavigate } from 'react-router-dom';
import Modals from '../../../components/modal';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ReactLoader from '../../../Loader/react-loader';
import ROUTES from '../../../Middleware/routes';
import TITLES from '../../../Helper/title';
import moment from "moment";
import { Helmet } from 'react-helmet';
import Pagination from '../../../components/pagination';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePickerValue from '../../../components/date-picker';
import ERR_MESSAGE from '../../../Helper/error-helper';
import { useLayoutContext } from '../../../context/context';
import { format, differenceInDays, isToday, isTomorrow } from 'date-fns';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

const styleLead = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height: 650,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

const customDate = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

const styleLeft = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 270,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

function Customers() {
    const [trainerList, setTrainerList] = useState([]);
    const [customAll, setCustomAll] = useState(false)
    const [openLead, setOpenLead] = useState(false);
    const [openDate, setOpenDate] = useState(false);
    const [userChangedInfo, setUserChangedInfo] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const [errors, setErrors] = useState('');
    const [openFreeze, setOpenFreeze] = useState(false);
    const [openLeft, setOpenLeft] = useState(false);
    const [deltId, setDeltId] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const navigate = useNavigate();
    const [total, setTotal] = useState("")
    const [total1, setTotal1] = useState("")
    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [filterItems, setFilterItems] = useState([])
    const [userId, setUserId] = useState(null)
    const [tabSwitch, setTabSwitch] = useState(0)
    const [freeze, setFreeze] = useState({
        reason: "",
        day: "",
        startFreeze: currentDate,
        endFreeze: ""
    })
    const [selectCustom, setSelectCustom] = useState(false)
    const [status, setStatus] = useState("")
    const { searchQuery } = useLayoutContext();
    const [endDate, setEndDate] = useState(null)
    let message = localStorage.getItem("message")
    const pageSize = 7;
    const [search, setSearch] = useState("")
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1)
    const endIndex = search === "" ? Math.min(startIndex + pageSize - 1, total) : Math.min(startIndex + pageSize - 1, total1);
    const totalPages = search === "" ? Math.ceil(total / pageSize) : Math.ceil(total1 / pageSize)

    useEffect(() => {
        if (message) {
            toast.success(message);
            localStorage.removeItem("message")
        }
        return () => {
            message = null
        };
    }, []);

    const [data1, setData1] = useState({
        discount_type: "",
        discount: "",
        base_amount: "",
        net_amount: "",
        paid_amount: "",
        balance_amount: "",
        month: "",
        days: "0",
        start_date: currentDate,
        end_date: "",
        mode_of_payment: "",
        transaction_id: "",
        plan_type: "1",
        trainer_id: ""
    })

    const [custom, setCustom] = useState({
        startDate: currentDate,
        endDate: ""
    })

    const handleOpenLead = (item) => {
        // setId(id)
        setOpenLead(true);
        if (item) {
            getMembershipDetail(item.user_transactions[0]?.id)
        }
        setUserId(item?.id)
        setEndDate(item.end_date)
    };

    const handleCloseLead = () => {
        setOpenLead(false)
        setData1({
            ...data1,
            discount_type: "",
            discount: "",
            base_amount: "",
            net_amount: "",
            paid_amount: "",
            balance_amount: "",
            month: "",
            days: "",
            start_date: "",
            end_date: "",
            mode_of_payment: "",
            transaction_id: ""
        })
    };

    const handleOpenDate = (item) => {
        setTabSwitch(3)
        setOpenDate(true);
        setCustom({
            ...custom,
            startDate: localStorage.getItem("startDate") || currentDate,
            endDate: localStorage.getItem("endDate"),

        })
    };

    const handleCloseDate = () => {
        setOpenDate(false)
        setCustom({
            ...custom,
            startDate: "",
            endDate: "",

        })
    };

    useEffect(() => {
        if (tabSwitch !== 3)
            localStorage.removeItem("startDate")
        localStorage.removeItem("endDate")
    }, [tabSwitch])

    async function getTrainerApi() {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-list?branch_id=${localStorage.getItem("branchFilter")}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            const filteredTrainer = result.body.data.filter(item => item.role_id == 3)
            setTrainerList(filteredTrainer);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    const handleChange2 = (e) => {
        setErrors('');
        const name = e.target.name;
        let value = e.target.value;
        if ((name === 'days' || name === 'month' || name === "base_amount" || name === 'net_amount' || name === 'paid_amount') && parseFloat(value) < 0) {
            value = 0;
        }
        if (name === 'month' && parseFloat(value) > 12) {
            value = 0;
        }
        if (name === 'discount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'net_amount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'paid_amount') {
            const netAmount = parseFloat(data1?.net_amount);
            value = Math.min(parseFloat(value), netAmount);
        }
        setData1((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
        if (name === 'discount_type' && value === '2') {
            setData1((prevInfo) => ({
                ...prevInfo,
                discount: '',
            }));
        }

        if (name === 'base_amount' || name === 'discount_type' || name === 'discount') {
            calculateNetAmount();
        }

        if (name === 'net_amount' || name === 'paid_amount') {
            calculateBalance();
        }
        if (name === 'discount_type' && value === '1') {
            if (name === 'discount' && value > 100) {
                setData1((prevInfo) => ({
                    ...prevInfo,
                    discount: 100,
                }));
            }
        }

        if (name === "month" || name === "days") {
            setUserChangedInfo(true);
        }
    };

    const calculateNetAmount = () => {
        const baseAmount = Number(data1?.base_amount) || 0;
        const discount = Number(data1?.discount) || 0;
        if (data1?.discount_type === '1') {
            const discountAmount = (baseAmount * discount) / 100;
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else if (data1?.discount_type === '2') {
            const discountAmount = data1.discount
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else {
            setData1((prevInfo) => ({ ...prevInfo, net_amount: data1?.base_amount, paid_amount: data1?.base_amount }));
        }
    };

    const calculateBalance = () => {
        const netAmount = Number(data1?.net_amount) || 0;
        const paid_amount = Number(data1?.paid_amount) || 0;
        const balance_amount = netAmount - paid_amount;
        setData1((prevInfo) => ({ ...prevInfo, balance_amount: balance_amount }));
    };

    const handleStart = (newDate) => {
        if (data1.month > 0 || data1.days > 0) {
            const startDate = new Date(newDate) || currentDate;
            const month = parseInt(data1?.month || 0);
            const days = parseInt(data1?.days || 0);
            const totalDays = month * 29 + days;
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + totalDays);
            const formattedEndDate = endDate.toISOString().split('T')[0];
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: formattedEndDate,
            }));
        }
        else {
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: "",
            }));
        }
    };

    const handleBlur = (e) => {
        setData1({
            ...data1,
            [e.target.name]: 0
        })
    }
    useEffect(() => {
        calculateNetAmount();
    }, [data1?.base_amount, data1?.discount_type, data1?.discount]);

    useEffect(() => {
        calculateBalance();
    }, [data1?.net_amount, data1?.paid_amount, data1?.base_amount]);

    useEffect(() => {
        setData1((prevInfo) => ({ ...prevInfo, paid_amount: data1?.net_amount }));
    }, [data1?.net_amount]);

    useEffect(() => {
        if (userChangedInfo) {
            handleStart(new Date().toISOString().split('T')[0]);
            setUserChangedInfo(false);
        }
    }, [data1?.month, data1?.days, userChangedInfo]);



    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { base_amount,
            month,
            paid_amount,
            net_amount,
            mode_of_payment,
            start_date,
            trainer_id
        } = data1
        if (data1.plan_type === '2') {
            if (!trainer_id || trainer_id === undefined) {
                formIsValid = false;
                errors["trainer_id"] = ERR_MESSAGE.EMPTY_TRAINER
            }
        }
        if (!base_amount || base_amount.length === 0) {
            formIsValid = false;
            errors["base_amount"] = ERR_MESSAGE.EMPTY_BASEAMOUNT;
        }
        if (!net_amount || net_amount.length === 0) {
            formIsValid = false;
            errors["net_amount"] = ERR_MESSAGE.EMPTY_NETAMOUNT;
        }
        if (!paid_amount || paid_amount.length === 0) {
            formIsValid = false;
            errors["paid_amount"] = ERR_MESSAGE.EMPTY_PAIDAMOUNT;
        }
        if (!month) {
            formIsValid = false;
            errors["month"] = ERR_MESSAGE.EMPTY_PLANPERIOD;
        }
        else {
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(endDate);

            if (startDateObj <= endDateObj) {
                formIsValid = false;
                errors["start_date"] = "Enter valid date";
            }
        }
        if (!mode_of_payment || mode_of_payment.length === 0) {
            formIsValid = false;
            errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
        }
        setErrors(errors);
        return formIsValid;
    }
    
    const setMemberSince = (newDate) => {
        setData1((prevFields) => ({
            ...prevFields,
            end_date: newDate,
        }));
    };

    async function postMembershipApi(e) {
        e.preventDefault()
        console.log("00")
        if (handleValidation()) {
            let data = JSON.stringify({
                "plan_type": data1.plan_type ? data1.plan_type : "1",
                "user_id": userId,
                "discount_type": data1.discount_type,
                "discount": data1.discount,
                "base_amount": data1.base_amount,
                "net_amount": data1.net_amount,
                "paid_amount": data1.paid_amount,
                "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
                "month": data1.month,
                "days": data1.days ? data1.days : "0",
                "start_date": data1.start_date,
                "end_date": data1.end_date,
                "mode_of_payment": data1.mode_of_payment,
                "transaction_id": data1.transaction_id,
                "trainer_id": data1.trainer_id
            });
            setLoading(true);
            const result = await Services.postService("admin/purchase-membership", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleCloseLead()
                setData1({
                    ...data1,
                    discount_type: "",
                    discount: "",
                    base_amount: "",
                    net_amount: "",
                    paid_amount: "",
                    balance_amount: "",
                    month: "",
                    days: "",
                    start_date: "",
                    end_date: "",
                    mode_of_payment: "",
                    transaction_id: ""
                })
                getApi(currentPage);

            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    const handleValidationDate = () => {
        let errors = {};
        let formIsValid = true;
        const {
            endDate,
            startDate
        } = custom
        if (!startDate) {
            formIsValid = false;
            errors["base_amount"] = ERR_MESSAGE.EMPTY_PLANSTART;
        }
        if (!endDate) {
            formIsValid = false;
            errors["endDate"] = ERR_MESSAGE.EMPTY_ENDDATE;
        }

        setErrors(errors);
        return formIsValid;
    }

    const setCustomDate = (newDate) => {
        setCustom((prevFields) => ({
            ...prevFields,
            startDate: newDate
        }));
    };
    const setCustomEndDate = (newDate) => {
        setCustom((prevFields) => ({
            ...prevFields,
            endDate: newDate,
        }));
    };
    async function postCustomApi(e) {
        e.preventDefault()
        if (handleValidationDate()) {
            handleCloseDate()
            getApi(currentPage, "customDate");
            setSelectCustom(true)
            localStorage.setItem("startDate", custom.startDate)
            localStorage.setItem("endDate", custom.endDate)
        }
    }

    async function getMembershipDetail(id) {
        setLoading(true)
        let result = await Services.getService(`admin/get-membership-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (result?.body === null) {
                const updatedData = {
                    ...result.body,
                    days: 0,
                    balance_amount: 0,
                    start_date: new Date(),
                    trainer_id: result?.body?.trainer_name
                };
                setData1(updatedData);
            }
            else {
                const updatedData = {
                    ...result.body,
                    days: result.body.days || 0,
                    balance_amount: result.body.balance_amount || 0,
                    start_date: new Date()

                };
                setData1(updatedData);
            }
            setLoading(false)
        }
        else {
            setLoading(false);
        }
    }

    const setStartFreeze = (newDate) => {
        setErrors("")
        if (freeze.day > 0) {
            const startDate = new Date(newDate) || currentDate;
            const days = parseInt(freeze.day || 0);
            const totalDays = days - 1;
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + totalDays);
            const formattedEndDate = endDate.toISOString().split('T')[0];
            setFreeze((prevFields) => ({
                ...prevFields,
                startFreeze: newDate,
                endFreeze: formattedEndDate
            }));
        }
        else {
            setFreeze((prevFields) => ({
                ...prevFields,
                startFreeze: newDate,
                endFreeze: ""
            }));
        }
    };

    const setEndFreeze = (newDate) => {
        setFreeze((prevFields) => ({
            ...prevFields,
            endFreeze: newDate,
        }));
    };

    async function handleSearch(value) {
        let url = `admin/get-customers?company_id=${localStorage.getItem("company_id")}&offset=${currentPage}&limit=${7}&search=${value}`;
        if (tabSwitch === 0) {
            url = `admin/get-customers?company_id=${localStorage.getItem("company_id")}&offset=${currentPage}&limit=${7}&search=${value}`
        }
        if (tabSwitch !== 0 && tabSwitch !== 3 && customDate !== "customDate") {
            const filterType = tabSwitch === 1 ? "active" : "recentLeft";
            url += `&filter=${filterType}`;
        }

        if (customDate === "customDate" || tabSwitch === 3) {
            url += `&start_date=${custom.startDate || localStorage.getItem("startDate")}&end_date=${custom.endDate || localStorage.getItem("endDate")}}`;
        }
        let result = await Services.getService(url);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setFilterItems(result.body?.data)
            setTotal1(result?.body?.data?.length)
        }
        else {
        }
    }

    useEffect(() => {
        if (searchQuery) {
            handleSearch(searchQuery);
        }
    }, [searchQuery]);

    async function getGymLeftAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/gym-left?customer_id=${userId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleCloseLeft();
            getApi(currentPage)
            toast.success(result.message)
        } else {
            setLoading(false);
            toast.error(result.message)
        }
    }

    const handleChangeFreeze = (e) => {
        setErrors("")
        const { name, value } = e.target;
        setFreeze((prevFreeze) => {
            const updatedFreeze = {
                ...prevFreeze,
                [name]: value
            };
            if (name === "day" && value > 0) {
                const startDate = new Date(updatedFreeze.startFreeze || currentDate);
                const days = parseInt(value, 10) || 0;
                const totalDays = days - 1;
                const endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + totalDays);
                updatedFreeze.endFreeze = endDate.toISOString().split('T')[0];
            } else if (name === "day" && value === "") {
                updatedFreeze.endFreeze = "";
            }

            return updatedFreeze;
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getApi(page);
    };

    const handleDeleteOpen = (id) => {
        setDeltId(id);
        setOpenDelete(true);
    };

    const handleOpenFreeze = (status, customerId, freeze) => {
        setOpenFreeze(true);
        setUserId(customerId)
        setStatus(status == 'Freezed' ? 3 : '')
        if (status === 'Freezed') {
            setFreeze({
                ...freeze,
                startFreeze: freeze.start_date,
                endFreeze: freeze?.end_date,
                day: freeze?.freeze_days,
                reason: freeze.reason,
            })
        }
    };

    const handleCloseFreeze = () => {
        setErrors("")
        setOpenFreeze(false)
        setFreeze({
            ...freeze,
            startFreeze: '',
            endFreeze: "",
            day: "",
            reason: "",
        })
    };

    const handleOpenLeft = (id) => {
        setUserId(id)
        setOpenLeft(true);
    };

    const handleCloseLeft = () => {
        setOpenLeft(false)
    };

    const role_id = localStorage.getItem("role_id")
    const handleDeleteClose = () => setOpenDelete(false);
    async function getApi(pageNumber, customDate) {
        console.log(customDate, "customDate");
        setLoading(true);

        let url = `admin/get-customers?company_id=${localStorage.getItem("company_id")}&offset=${pageNumber}&limit=${7}&branch_id=${localStorage.getItem("branchFilter")}`;
        if (tabSwitch === 0) {
            url = `admin/get-customers?company_id=${localStorage.getItem("company_id")}&offset=${pageNumber}&limit=${7}&branch_id=${localStorage.getItem("branchFilter")}`
        }
        if (tabSwitch !== 0 && tabSwitch !== 3 && customDate !== "customDate") {
            const filterType = tabSwitch === 1 ? "active" : "recentLeft";
            url += `&filter=${filterType}`;
        }

        if (customDate === "customDate") {
            url += `&start_date=${custom.startDate}&end_date=${custom.endDate}`;
        }

        try {
            let result = await Services.getService(url);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setCustomerList(result?.body?.data);
                setTotal(result?.body?.totalcount);
                if (customDate !== "customDate") {

                    setSelectCustom(false)
                }
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    }

    console.log(custom.startDate, custom.endDate, "0000")
    async function getDeleteAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/delete-customer?id=${deltId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleDeleteClose()
            getApi(currentPage)
        } else {
            setLoading(false);
        }
    }

    const handleClick = (id) => {
        navigate(ROUTES.CUSTOMER_PROFILE, { state: { id } })
    }

    const handleEdit = (id) => {
        navigate(ROUTES.EDIT_CUSTOMER_DETAIL, { state: { id } })
    }

    useEffect(() => {
        if (tabSwitch !== 3) {
            getApi(currentPage)
        }
        setCustomAll(false)
        getTrainerApi()
    }, [currentPage, tabSwitch, customAll])

    function formatMobileNumber(mobileNumber) {
        if (mobileNumber && mobileNumber.length >= 10) {
            const firstDigits = mobileNumber.slice(0, 1);
            const lastDigits = mobileNumber.slice(-3);
            const formattedNumber = `${firstDigits} xxxxxx ${lastDigits}`;
            return formattedNumber;
        }
        return mobileNumber;
    }

    const getUserStatus = (endDate) => {

        const currentDate = new Date();
        const endDateParsed = new Date(endDate);
        const daysLeft = differenceInDays(endDateParsed, currentDate);
        const daysSinceExpiration = differenceInDays(currentDate, endDateParsed);

        if (daysLeft > 7) {
            return { status: 'Active', message: 'Active' };
        } else if (isToday(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Today' };
        } else if (isTomorrow(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Tomorrow' };
        } else if (daysLeft > 0 && daysLeft <= 7) {
            return { status: 'Warning', message: `Expires on ${format(endDateParsed, 'd MMM')}` };
        } else if (daysSinceExpiration <= 7 && daysSinceExpiration > 0) {
            return { status: 'Expired', message: `Expired on ${format(endDateParsed, 'd MMM')}` };  // Shows day and month
        } else {
            return { status: 'Expired', message: 'Expired' };
        }
    };

    const handleValidationFreeze = () => {
        let errors = {};
        let formIsValid = true;
        const { reason, day
        } = freeze
        if (!reason) {
            formIsValid = false;
            errors["reason"] = ERR_MESSAGE.EMPTY_REASON;
        }
        if (!day) {
            formIsValid = false;
            errors["day"] = ERR_MESSAGE.EMPTY_DAY;
        }
        setErrors(errors);
        return formIsValid;
    }

    async function postFreezeApi(e, status) {
        e.preventDefault()
        if (handleValidationFreeze()) {
            let data;
            if (status === 3) {
                data = JSON.stringify({
                    "customer_id": userId
                });
            }
            else {
                data = JSON.stringify({
                    "freeze_days": freeze.day,
                    "start_date": freeze.startFreeze,
                    "end_date": freeze.endFreeze,
                    "freeze_reason": freeze.reason,
                    "customer_id": userId
                });
            }
            setLoading(true);
            const result = await Services.postService(`admin/freeze-unfreeze-membership`, data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleCloseFreeze()
                setFreeze({
                    ...freeze,
                    startFreeze: '',
                    endFreeze: "",
                    day: "",
                    reason: "",
                })
                getApi(currentPage)
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>{TITLES.CUSTOMER}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <Modals />
            <section className="main-spacing mt-3 main-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 leads-table">
                            <div className="row pb-2">
                                <div className="col-12 col-lg-8">
                                    <div className="leads-main d-block d-lg-flex  align-items-center gap-2 gap-md-4 mb-2 mb-md-0">
                                        <div className="leads-head">
                                            <h3>Customers</h3>
                                        </div>
                                        {/* <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3"> */}
                                        {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">All Customers</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}

                                        {/* <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div> */}
                                        {/* </div> */}

                                        <div className='d-flex gap-2 flex-wrap mb-4 mb-sm-0'>
                                            <a
                                                className={`btn-active border-right ${tabSwitch === 0 ? "active" : ""}`}
                                                onClick={() => {
                                                    setTabSwitch(0)
                                                    setCustomAll(true)
                                                }}                                            >
                                                All
                                            </a>
                                            <a
                                                className={`btn-active border-right ${tabSwitch === 1 ? "active" : ""}`}
                                                onClick={() => setTabSwitch(1)}
                                            >
                                                Active
                                            </a>

                                            <a className={`btn-active border-right ${tabSwitch === 2 ? "active" : ""}`} onClick={() => setTabSwitch(2)}>
                                                Recent Left
                                            </a>
                                            <a className={`btn-active border-right ${tabSwitch === 3 ? "active" : ""}`} onClick={handleOpenDate} >
                                                Custom Date
                                            </a>
                                            {
                                                (selectCustom) &&
                                                <button type="button" className="btn add-leads-btn d-flex align-items-center custom_date" style={{ color: "#ed6214", padding: " 8px 15px 8px 15px", fontSize: "12px" }}> From:  <img src={CALANDER} className="img-fluid calander ms-1" alt='' /> <div style={{ marginRight: "10px" }} >{moment(localStorage.getItem("startDate")).format('Do MMM, YYYY')} </div> To :  <img src={CALANDER} className="img-fluid calander ms-1" alt='' />  <div className='' >{moment(localStorage.getItem("endDate")).format('Do MMM, YYYY')} </div> </button>
                                            }

                                            {/* <div class="dropdown" style={{ marginTop: "-6px" }}>
                                                <button
                                                    class={`btn dropall btn-active ${tabSwitch === 0 && "active"}`}
                                                    type="button"
                                                    style={{ border: "none" }}
                                                    onClick={() => {
                                                        setTabSwitch(0)
                                                        setCustomAll(true)
                                                    }}

                                                >
                                                    All
                                                </button>

                                                <button
                                                    class="btn dropdown-toggle px-1"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    style={{ border: "none" }}
                                                >

                                                </button>

                                                <ul class="dropdown-menu py-1" onClick={handleOpenDate} aria-labelledby="dropdownMenuButton1">
                                                    <li><a class="dropdown-item py-0 f13">Custom Date</a></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4" >
                                    <div className="leads-main d-block justify-content-end d-sm-flex align-items-center gap-2 gap-md-4 mb-2 mb-md-0">
                                        <div className="search-category position-relative my-4 my-lg-0 ">
                                            <form className="d-flex" >
                                                <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search widthsearch" type="search" placeholder="Search Customers..."
                                                    value={search}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value)
                                                        handleSearch(e.target.value)
                                                    }}
                                                />
                                                <img src={SEARCH_ICON} className="search-icon custom-search-icon" alt='' />
                                            </form>
                                        </div>
                                        <div className="add-label text-end d-flex align-items-center justify-content-start justify-content-sm-end" >
                                            <button type="button" onClick={() => { navigate(ROUTES.ADD_CUSTOMER) }} className="btn add-leads-btn d-flex  align-items-center"><img src={ADD_ICON} className="img-fluid me-2" alt='' /> Add New Customers</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                search?.length == 0 && searchQuery == 0 && customerList?.length > 0 ? <>
                                    <div className="table-responsive leads-table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="leads-table-head">Name</th>
                                                    <th scope="col" className="leads-table-head">Training Type</th>
                                                    <th scope="col" className="leads-table-head">Start date</th>
                                                    <th scope="col" className="leads-table-head">End date</th>
                                                    <th scope="col" className="leads-table-head">Paid Date</th>
                                                    <th scope="col" className="leads-table-head">Paid Amount</th>
                                                    <th scope="col" className="leads-table-head">Membership</th>
                                                    <th scope="col" className="leads-table-head">Status</th>
                                                    <th scope="col" className="leads-table-head">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customerList?.length === 0 ? <div className='notFound'>No customer found</div> : customerList?.map((items) =>
                                                    <tr key={items?.id}>
                                                        <th scope="row" className="leads-data-head d-flex">
                                                            <span className='customer-pic-head'>
                                                                <img src={items?.profile_pic ? items?.profile_pic : USER_PROFILE} className='customer-pic me-2' alt='' />
                                                            </span>
                                                            <span>
                                                                {items?.name}
                                                                <span className='d-block'>
                                                                    {role_id === "1" || role_id === "2" ? items.whatsapp_no : formatMobileNumber(items.whatsapp_no)}
                                                                </span>
                                                            </span>
                                                        </th>

                                                        <td className="leads-table-data">
                                                            {
                                                                items.start_date === null ? "-" : <>
                                                                    {items?.user_transactions[0]?.plan_type === "1" ? "GT" : "PT"}</>
                                                            }
                                                        </td>
                                                        <td className="leads-table-data">
                                                            {items.start_date === null ? "-" : items?.start_date !== "null" ? moment(items.start_date).format('Do MMM, YYYY') : "-"}
                                                        </td> <td className="leads-table-data">
                                                            {items.start_date === null ? "-" : items?.end_date !== "null" || items.start_date !== null ? moment(items.end_date).format('Do MMM, YYYY') : "-"}
                                                        </td>

                                                        <td className="leads-table-data"> {items.plan_purchase_date === "null" ? "-" : items?.plan_purchase_date === null ? "-" : moment(items.plan_purchase_date).format('Do MMM, YYYY')}
                                                        </td>
                                                        <td className="leads-table-data">
                                                            {items?.paid_amount === "0.00" ? "-" : items?.paid_amount === null ? "-" : `\u20B9${items.paid_amount}`}
                                                        </td>
                                                        <td
                                                            onClick={() => {
                                                                if (items.status !== 'Active') {
                                                                    handleOpenLead(items)
                                                                }
                                                            }}
                                                        >
                                                            <span className={items.status === 'Active' || items.status === 'Freezed' ? "upgrade-text disbale" : "upgrade-text "} style={{ cursor: items.status !== 'Active' || items.status !== 'Freezed' ? "pointer" : "not-allowed" }}>
                                                                Upgrade Membership
                                                            </span>
                                                        </td>

                                                        <td className="leads-table-data">
                                                            {/* {items?.status === "0" || items?.status === "1" ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}{items?.status === "0" ? "Upcoming Membership" : items?.status === "1" ? "Active" : items?.status === "2" ? "Expired" : items?.status === "3" ? "Freezed " : "Gym Left"} */}

                                                            {
                                                                items.start_date === "null" || items.start_date === null ? "-" : <>

                                                                    {items.status !== "Active" ?
                                                                        <div className="red-dot"><span className="red-blink"></span></div> : items.status === 'Active' ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}

                                                                    {<span>
                                                                        {
                                                                            items.status

                                                                        }
                                                                    </span>
                                                                    }

                                                                </>
                                                            }

                                                        </td>
                                                        {/* <td><span className="upgrade-text">Upgrade Membership</span></td> */}
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center gap-3">
                                                                <span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleClick(items?.id, items)} >View</span>
                                                                {
                                                                    role_id === "3" ||  role_id === "1" ? "" : <>

                                                                        <img src={DELETE_ICON} style={{ cursor: "pointer" }} alt="" className="img-fluid" onClick={() => handleDeleteOpen(items.id)} />
                                                                    </>
                                                                }
                                                                <img src={EDIT_ICON} style={{ cursor: "pointer" }} onClick={() => handleEdit(items.id)} alt="" className="img-fluid" />
                                                                <div className="dropdown">
                                                                    <a
                                                                        type="button"
                                                                        id="dropdownMenuButton1"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <img
                                                                            src={THREE_DOTS}
                                                                            alt="3dots"
                                                                        />
                                                                    </a>
                                                                    <ul
                                                                        className="dropdown-menu"
                                                                        style={{ zIndex: "1000" }}
                                                                        aria-labelledby="dropdownMenuButton1 "
                                                                    >
                                                                        <li style={{ cursor: "pointer" }}>
                                                                            {
                                                                                (items.status === 3 || getUserStatus(items?.end_date).status === 'Active') &&
                                                                                <a
                                                                                    className="dropdown-item "
                                                                                    onClick={() =>
                                                                                        handleOpenFreeze(items.status, items.id, items?.freeze_membership)
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        items.status === 3 ? "Unfreeze membership" :
                                                                                            " Freeze membership"
                                                                                    }
                                                                                </a>
                                                                            }

                                                                        </li>
                                                                        <li style={{ cursor: "pointer" }}>
                                                                            <a
                                                                                className="dropdown-item"
                                                                                onClick={() =>
                                                                                    handleOpenLeft(items.id)
                                                                                }
                                                                            >
                                                                                Gym left
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>


                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        customerList?.length > 0 && <>
                                            <div className="row pt-3">
                                                <div className="col-6">
                                                    <a href=""><span className="page-entry">
                                                        {
                                                            total === 1 ? <>
                                                                Showing {total} entry

                                                            </> :
                                                                <>
                                                                    Showing {startIndex} to {endIndex} of {total} entries

                                                                </>
                                                        }
                                                    </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <nav aria-label="Page">
                                                        <ul className="pagination d-flex justify-content-end">
                                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </>
                                    }     </> : <> <div className="table-responsive leads-table-responsive">

                                        <table className="table">
                                            {
                                                filterItems.length === 0 ? "" : <thead>
                                                    <tr>
                                                        <th scope="col" className="leads-table-head">Name</th>

                                                        <th scope="col" className="leads-table-head">Training Type</th>

                                                        <th scope="col" className="leads-table-head">Start date</th>
                                                        <th scope="col" className="leads-table-head">End date</th>
                                                        <th scope="col" className="leads-table-head">Paid Date</th>
                                                        <th scope="col" className="leads-table-head">Paid Amount</th>
                                                        <th scope="col" className="leads-table-head">Membership</th>
                                                        <th scope="col" className="leads-table-head">Status</th>
                                                        <th scope="col" className="leads-table-head">Action</th>
                                                    </tr>
                                                </thead>
                                            }
                                            <tbody>
                                                {filterItems.length === 0 ? <div className='notFound'>No customer found</div> : filterItems?.map((items) =>
                                                    <tr key={items?.id}>
                                                        <th scope="row" className="leads-data-head d-flex">
                                                            <span className='customer-pic-head'>
                                                                <img src={items?.profile_pic ? items?.profile_pic : USER_PROFILE} className='customer-pic me-2' alt='' />
                                                            </span>
                                                            <span>
                                                                {items?.name}
                                                                <span className='d-block'>
                                                                    {role_id === "1" || role_id === "2" ? items.whatsapp_no : formatMobileNumber(items.whatsapp_no)}
                                                                </span>
                                                            </span>
                                                        </th>


                                                        <td className="leads-table-data">
                                                            {
                                                                items.start_date === null ? "-" : <>
                                                                    {items?.user_transactions?.[items?.user_transactions?.length - 1]?.plan_type === "1" ? "GT" : "PT"}</>
                                                            }

                                                        </td>
                                                        <td className="leads-table-data">
                                                            {items.start_date === null ? "-" : items?.start_date !== "null" || items.start_date !== null ? moment(items.start_date).format('Do MMM, YYYY') : "-"}
                                                        </td> <td className="leads-table-data">
                                                            {items.start_date === null ? "-" : items?.end_date !== "null" || items.start_date !== null ? moment(items.end_date).format('Do MMM, YYYY') : "-"}
                                                        </td>
                                                        <td className="leads-table-data"> {items.plan_purchase_date === "null" ? "-" : items?.plan_purchase_date === null ? "-" : moment(items.plan_purchase_date).format('Do MMM, YYYY')}
                                                        </td>
                                                        <td className="leads-table-data">
                                                            {items?.paid_amount === "0.00" ? "-" : items?.paid_amount === null ? "-" : `\u20B9${items.paid_amount}`}
                                                        </td>
                                                        <td
                                                            onClick={() => {
                                                                if (items !== 'Active') {
                                                                    handleOpenLead(items)
                                                                }
                                                            }}
                                                        >
                                                            <span className={items.status === 'Active' || items.status === 'Freezed' ? "upgrade-text disbale" : "upgrade-text "} style={{ cursor: items.status !== 'Active' || items.status !== 'Freezed' ? "pointer" : "not-allowed" }}>
                                                                Upgrade Membership
                                                            </span>
                                                        </td>


                                                        <td className="leads-table-data">
                                                            {/* {items?.status === "0" || items?.status === "1" ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}{items?.status === "0" ? "Upcoming Membership" : items?.status === "1" ? "Active" : items?.status === "2" ? "Expired" : items?.status === "3" ? "Freezed " : "Gym Left"} */}

                                                            {
                                                                items.start_date === "null" || items.start_date === null ? "-" : <>

                                                                    {items.status !== "Active" ?
                                                                        <div className="red-dot"><span className="red-blink"></span></div> : items.status === 'Active' ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}

                                                                    {<span>
                                                                        {
                                                                            items.status
                                                                        }
                                                                    </span>
                                                                    }

                                                                </>
                                                            }


                                                        </td>
                                                        {/* <td><span className="upgrade-text">Upgrade Membership</span></td> */}
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center gap-3">
                                                                <span className="upgrade-text" style={{ cursor: "pointer" }} onClick={() => handleClick(items?.id, items)} >View</span>
                                                                {
                                                                    role_id === "3" ||  role_id === "1" ? "" : <>

                                                                        <img src={DELETE_ICON} style={{ cursor: "pointer" }} alt="" className="img-fluid" onClick={() => handleDeleteOpen(items.id)} />
                                                                    </>
                                                                }
                                                                <img src={EDIT_ICON} style={{ cursor: "pointer" }} onClick={() => handleEdit(items.id)} alt="" className="img-fluid" />
                                                                <div className="dropdown">
                                                                    <a
                                                                        type="button"
                                                                        id="dropdownMenuButton1"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        <img
                                                                            src={THREE_DOTS}
                                                                            alt="3dots"

                                                                        />
                                                                    </a>
                                                                    <ul
                                                                        className="dropdown-menu"
                                                                        style={{ zIndex: "1000" }}
                                                                        aria-labelledby="dropdownMenuButton1 "
                                                                    >
                                                                        <li style={{ cursor: "pointer" }}>
                                                                            <a
                                                                                className="dropdown-item "
                                                                                onClick={() =>
                                                                                    handleOpenFreeze(items?.user_transactions[0]?.id, items.id)
                                                                                }
                                                                            >
                                                                                Freeze membership
                                                                            </a>
                                                                        </li>
                                                                        <li style={{ cursor: "pointer" }}>
                                                                            <a
                                                                                className="dropdown-item"
                                                                                onClick={() =>
                                                                                    handleOpenLeft(items.id)
                                                                                }
                                                                            >
                                                                                Gym left
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>


                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        filterItems?.length > 0 && <>
                                            <div className="row pt-3">
                                                <div className="col-6">
                                                    <a href=""><span className="page-entry">
                                                        {
                                                            total1 === 1 ? <>
                                                                Showing {total1} entry

                                                            </> :
                                                                <>
                                                                    Showing {startIndex} to {endIndex} of {total1} entries

                                                                </>
                                                        }
                                                    </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <nav aria-label="Page">
                                                        <ul className="pagination d-flex justify-content-end">
                                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </>
                                    }</>
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    open={openDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="modal-head">
                        <div
                            onClick={handleDeleteClose}
                            className=" position-relative mb-0 text-end pt-1"
                            style={{ cursor: "pointer" }}
                        >
                            <img src={CROSS_BTN} alt='' />
                        </div>
                        <div className="signin-head text-center pb-3">
                            <h2>Delete Customer</h2>
                        </div>
                        <div className="mb-4 position-relative">
                            <p>
                                Are you sure you want to delete the customer?

                            </p>
                        </div>
                        <div
                            className="mb-3 text-center position-relative submit-head"
                            onClick={getDeleteAPI}
                        >
                            <button
                                type="submit"
                                className="btn delete-btn"
                            >
                                Delete{" "}
                            </button>
                        </div>
                    </Box>
                </Modal>
            </section>
            <Modal
                open={openFreeze}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleCloseFreeze}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt='' />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>{status === 3 ? "Unfreeze Membership" : "Freeze Membership"}</h2>
                    </div>
                    <form className='create-field'>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">  Days</label><input type="number" className="form-control common-input" placeholder="Enter Days" name="day" value={freeze.day} onChange={handleChangeFreeze} disabled={status === 3} />
                                    {errors.day && <span className="err_msg">{errors.day}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Start date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={freeze?.startFreeze ? freeze.startFreeze : currentDate}
                                        setFn={setStartFreeze}
                                        status={status}
                                    />
                                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={freeze?.endFreeze}
                                        setFn={setEndFreeze}
                                        endFreezeDate={freeze?.endFreeze}
                                        name="planEndFreeze"
                                        status={status}
                                    />
                                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Freeze Reason </label>
                                    <textarea name="reason" value={freeze.reason} onChange={handleChangeFreeze} rows="3" cols="50" className="form-control common-input" disabled={status === 3} />
                                </div>
                                {errors.reason && <span className="err_msg">{errors.reason}</span>}

                            </div>
                        </div>
                    </form>
                    <div
                        className="mb-3 text-center position-relative submit-head"
                        onClick={(e) => postFreezeApi(e, status)}
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            {
                                status === 3 ? "Unfreeze" : "Freeze"
                            }

                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openLeft}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLeft} className="modal-head">
                    <div
                        onClick={handleCloseLeft}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt='' />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Gym Left</h2>
                    </div>
                    <div className="mb-4 position-relative">
                        <p>
                            Are you sure you want to remove the customer?
                            ? The customer has left the gym.
                        </p>
                    </div>
                    <div
                        onClick={getGymLeftAPI}
                        className="mb-3 text-center position-relative submit-head"
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            remove{" "}
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openLead}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLead} className="modal-head">
                    <div
                        onClick={handleCloseLead}
                        className=" position-relative mb-0 text-end pt-1 pe-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt='' />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2 className='modalHeading'>Upgrade Membership</h2>
                    </div>

                    <form className='create-field purchase-create-field'>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Training type </label>
                                    <select name="plan_type" className="form-select common-input" value={data1?.plan_type} onChange={handleChange2} >
                                        <option value="1">General training</option>
                                        <option value="2">Personal training</option>
                                    </select>
                                </div>
                            </div>
                            {
                                data1?.plan_type == 2 && <div className="col-md-12">
                                    <div className="mb-2 mb-xxl-3">
                                        <label className="form-label mb-0">Trainer </label>

                                        <select name='trainer_id' value={data1.trainer_id} onChange={handleChange2} className="form-select common-input" aria-label="Default select example">
                                            <option value="">Select Trainer</option>
                                            {
                                                trainerList?.map((item) => {
                                                    return (
                                                        <>
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        </>
                                                    )
                                                })
                                            }

                                        </select>
                                        {errors.trainer_id && <span className="err_msg">{errors.trainer_id}</span>}
                                    </div>
                                </div>
                            }

                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Base Amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            name="base_amount"
                                            value={data1?.base_amount?.slice(0, 15)}
                                            onChange={handleChange2}
                                            className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.base_amount && <span className="err_msg">{errors.base_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">DISCOUNT type </label>
                                    <select name="discount_type" className="form-select common-input" value={data1?.discount_type} onChange={handleChange2} >
                                        <option value="">Select</option>
                                        <option value="1">%(Percentage)</option>
                                        <option value="2">Lump sum</option>
                                    </select>
                                    {errors.discount_type && <span className="err_msg">{errors.discount_type}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">DISCOUNT</label><input type="number" className="form-control common-input" placeholder="Enter Discount" value={data1?.discount} onChange={handleChange2} name="discount" disabled={!data1?.discount_type} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Net amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            name="net_amount"
                                            value={data1?.net_amount}
                                            onChange={handleChange2}
                                            readOnly={data1?.discount_type === "1" || !data1?.discount_type || data1.discount_type === "2"} className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.net_amount && <span className="err_msg">{errors.net_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">PAID amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" placeholder="Enter paid amount" value={data1?.paid_amount} onChange={handleChange2} name="paid_amount"
                                            className="form-control common-input dollarInput"
                                        />
                                    </div>
                                    {errors.paid_amount && <span className="err_msg">{errors.paid_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" placeholder="Enter balance amount" value={data1?.balance_amount}

                                            name="balance_amount"
                                            className="form-control common-input dollarInput"
                                            onChange={(e) => {
                                                handleChange2(e);

                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">MONTH</label><input type="number" className="form-control common-input" placeholder="Enter Month" value={data1?.month} onChange={handleChange2} name="month" />
                                    {errors.month && <span className="err_msg">{errors.month}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Days</label><input type="number" onBlur={handleBlur} className="form-control common-input" placeholder="Enter days" value={data1?.days}
                                    onChange={handleChange2} name="days" />

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Start date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.start_date ? data1.start_date : currentDate}
                                        setFn={handleStart}
                                    />
                                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.end_date}
                                        setFn={setMemberSince}
                                        endDate={data1?.end_date}
                                        name="planEnd"
                                    />
                                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">PAYMENT MODE</label>
                                    <select name="mode_of_payment" className="form-select common-input" value={data1?.mode_of_payment} onChange={handleChange2} >
                                        <option selected>Select</option>
                                        <option value="cash">Cash</option>
                                        <option value="gpay">Gpay</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={data1?.transaction_id} onChange={handleChange2} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                                    {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="mt-4 mb-3 text-center position-relative submit-head">
                        <button onClick={postMembershipApi}
                            type="submit"
                            className="common-btnn w-100"
                        >
                            Submit
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openDate}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description "
                className='modalcustomdate'
            >
                <Box sx={customDate} className="modal-head">

                    <div className="signin-head text-center pb-3 position-relative pt-3">
                        <h2 className='modalHeading'>Custom Date</h2>
                        <div
                            onClick={handleCloseDate}
                            className=" position-absolute close-btn-date mb-0 text-end pt-1 pe-1"
                            style={{ cursor: "pointer" }}
                        >
                            <img src={CROSS_BTN} alt='' />
                        </div>
                    </div>

                    <form className='create-field '>
                        <div className='row'>
                            <div className="col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Start date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={custom?.startDate}
                                        setFn={setCustomDate}
                                    />
                                    {errors.startDate && <span className="err_msg">{errors.startDate}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={custom.endDate}
                                        setFn={setCustomEndDate}

                                    />
                                    {errors.endDate && <span className="err_msg">{errors.endDate}</span>}
                                </div>
                            </div>

                        </div>
                        <div className="mt-4 mb-3 text-center position-relative submit-head">
                            <button onClick={postCustomApi}
                                type="submit"
                                className="common-btnn w-100"
                            >
                                Submit
                            </button>
                        </div>
                    </form>

                </Box>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default Customers;