import axios from 'axios';
import DEVELOPMENT_CONFIG from './config';

const BASE_URL = process.env.REACT_APP_BASE_URL;


const Services = {
  postService: async function (url, data) {
    const access_token = localStorage.getItem("access_token");
    var config = {
      method: "post",
      url: BASE_URL + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      },
      data: data
    };
    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        let refreshToken = localStorage.getItem("refresh_token");
        let refreshData = {
          "refresh_token": refreshToken,
        };
        try {
          let refreshTokenResult = await Services.postService("auth/refresh-token", refreshData);
          if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("access_token", refreshTokenResult.body.token);
            localStorage.setItem("refresh_token", refreshTokenResult.body.refresh_token);
            config.headers.Authorization = 'Bearer ' + refreshTokenResult.body.token;
            const retryResponse = await axios.request(config);
            return retryResponse.data;
          }
        } catch (refreshError) {
          return refreshError;
        }
      }
      return error;
    }
  },
  postServiceIEmail: async function (url, data) {
    const access_token = localStorage.getItem("access_token");
    var config = {
      method: "post",
      url: BASE_URL + url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + access_token
      },
      data: data
    };
    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        let refreshToken = localStorage.getItem("refresh_token");
        let refreshData = {
          "refresh_token": refreshToken,
        };
        try {
          let refreshTokenResult = await Services.postService("auth/refresh-token", refreshData);
          if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("access_token", refreshTokenResult.body.token);
            localStorage.setItem("refresh_token", refreshTokenResult.body.refresh_token);
            config.headers.Authorization = 'Bearer ' + refreshTokenResult.body.token;
            const retryResponse = await axios.request(config);
            return retryResponse.data;
          }
        } catch (refreshError) {
          return refreshError;
        }
      }
      return error;
    }
  },

  getService: async function (url, data) {
    const access_token = localStorage.getItem("access_token");
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: BASE_URL + url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        let refreshToken = localStorage.getItem("refresh_token");
        let refreshData = {
          "refresh_token": refreshToken,
        };
        try {
          let refreshTokenResult = await Services.postService("auth/refresh-token", refreshData);
          if (refreshTokenResult.code === DEVELOPMENT_CONFIG.statusCode) {
            localStorage.setItem("access_token", refreshTokenResult.body.token);
            localStorage.setItem("refresh_token", refreshTokenResult.body.refresh_token);
            config.headers.Authorization = 'Bearer ' + refreshTokenResult.body.token;
            const retryResponse = await axios.request(config);
            return retryResponse.data;
          }
        } catch (refreshError) {
          return refreshError;
        }
      }
      return error;
    }
  }
};

export default Services;
